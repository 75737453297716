.section-afterpay {
  .site-content {
    max-width: 100%;
    .promo-section-title {
      font-size: 20px;
      padding-bottom: 10px;
      line-height: 28px;
      @include breakpoint($medium-up) {
        font-size: 40px;
      }
    }
    .button {
      font-size: 12px;
      letter-spacing: 0.15em;
      margin: 10px;
    }
    .afterpay-faq--how-it-works {
      .basic-grid__header {
        margin: 20px 0;
        font-size: 20px;
        line-height: 29px;
        @include breakpoint($medium-up) {
          font-size: 40px;
        }
      }
      .text-image-block {
        flex-flow: column;
        padding: 20px;
        border: 0;
        @include breakpoint($medium-up) {
          padding: 40px 20px;
        }
        .text-image-block__inner {
          width: 100%;
          .text-image-block__header {
            font-size: 15px;
            font-weight: 400;
          }
          .text-image-block__text {
            font-weight: 400;
            text-align: center;
          }
        }
        .text-image-block__image {
          text-align: center;
          padding-bottom: 10px;
        }
      }
    }
    .afterpay-faq--create-account {
      text-align: center;
      .chat-parallax__subtitle {
        line-height: 24px;
      }
    }
    .afterpay-faq--account-access {
      text-align: center;
      margin: 20px 0;
      .promo-section-title {
        line-height: 25px;
        width: 71%;
        margin: 0 auto;
        @include breakpoint($medium-up) {
          line-height: 40px;
          width: 100%;
        }
      }
      .chat-parallax__content {
        padding-top: 15px;
        margin: 0 5px;
        @include breakpoint($medium-up) {
          padding-top: 35px;
        }
        .chat-parallax__subtitle {
          font-size: 15px;
          line-height: 28px;
          margin-bottom: 0;
          letter-spacing: 0.02em;
        }
      }
    }
    .afterpay-faq--common-faq {
      text-align: center;
      margin-top: 40px;
      .faq-title {
        font-size: 20px;
        @include breakpoint($medium-up) {
          font-size: 40px;
        }
      }
      .faq_sections {
        text-align: #{$ldirection};
        margin: 0 20px;
        position: relative;
        @include breakpoint($medium-up) {
          margin: 0 40px;
        }
        .faq {
          &__question {
            @include icon('plus', after);
            font-size: 18px;
            &:after {
              display: inline-block;
              font-family: icons;
              font-size: 12px;
              height: 100%;
              position: absolute;
              #{$rdirection}: 10px;
              font-weight: 400;
            }
          }
          &__answer {
            margin-#{$ldirection}: 15px;
            font-size: 13px;
            display: none;
          }
          &.faq--active {
            .faq__question {
              @include icon('minus', after);
            }
            .faq__answer {
              display: block;
            }
          }
        }
      }
    }
  }
}
