.checkout {
  .cross_sell_container {
    .cart_cross_sell_item {
      min-height: 310px;
      .item_info {
        .add_button {
          #{$rdirection}: 21px;
        }
      }
    }
  }
  .viewcart_header_text {
      color: $color-black;
      float: #{$ldirection};
      font-size: 36px;
      line-height: get-line-height(36px, 36px);
      padding: 28px 20px;
      font-weight: bold;
      position: relative;
  }
  .myaccountheader {
    @include breakpoint($medium-up) {
        color: $color-black;
        font-family: $font--helvetica-neue-light;
    }   
  }
}
