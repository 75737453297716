#csr_header {
  #cust_cart,
  #poa_app {
    border-left: 1px $color-red solid;
    padding: 0 20px;
    border-bottom: none;
  }
}

.welcome-15 {
  .site-email-signup__terms-details {
    overflow-y: auto !important;
  }
}
