.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        font-size: 12px;
        font-weight: 400;
        font-family: $primary-font;
        color: $color-blue !important;
        &:hover {
          color: $color-black !important;
          text-decoration: underline !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.vendor-header-container {
  #optanon-popup-more-info-bar {
    .optanon-status-editable,
    .optanon-status-on {
      input[type='checkbox'] {
        & ~ label {
          white-space: nowrap;
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFloatingRoundedCorner {
      @include swap_direction(padding, 25px 25px 25px 22px);
      width: 100%;
      bottom: 0 !important;
      #{$rdirection}: 0;
      border: 5px solid $color-black;
      line-height: 1.2;
      @include breakpoint($medium-up) {
        width: 295px;
        bottom: 10px !important;
      }
      div,
      p {
        display: inline !important;
        font-size: 14px;
      }
      #onetrust-group-container {
        margin-#{$ldirection}: 0;
        float: none;
        #onetrust-policy {
          margin-top: 0;
          .banner-header {
            margin: 0;
          }
          #onetrust-policy-text {
            padding: 0;
            line-height: normal;
            float: none;
            color: $color-black;
          }
          #onetrust-close-btn-container {
            margin-#{$rdirection}: -5%;
            margin-top: -5%;
          }
        }
      }
      #onetrust-button-group-parent {
        padding: 0;
        float: none;
        #onetrust-pc-btn-handler {
          padding: 0;
          margin: 0;
          width: auto;
          letter-spacing: normal;
          font-size: 14px;
          line-height: normal;
          color: $color-blue !important;
          text-decoration: none;
          opacity: 1;
          &:hover {
            color: $color-black !important;
            text-decoration: underline;
          }
        }
      }
    }
    &.otFlat {
      #onetrust-button-group {
        button {
          text-align: #{$rdirection};
          padding-#{$rdirection}: 17%;
          text-decoration: underline;
          opacity: 1;
          width: auto;
          @include breakpoint($medium-up) {
            padding-#{$rdirection}: 0;
          }
        }
        .onetrust-pc-btn-handler {
          margin-bottom: 0;
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          top: auto;
          #{$ldirection}: 95%;
          bottom: 20px;
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none;
      }
    }
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(100% - 190px);
        }
        #pc-title {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $color-blue !important;
    font-size: 12px !important;
    letter-spacing: normal;
    &:hover {
      text-decoration: underline;
      color: $color-black !important;
    }
  }
}
