.basic-carousel-formatter {
  @include breakpoint($landscape-up) {
    .slick-prev {
      #{$ldirection}: 15px;
    }
    .slick-next {
      #{$rdirection}: 10015px;
    }
  }
}
