.product-brief__cta {
  margin: 0;
  padding: 1em 1.25em;
  font-size: 14px;
  line-height: 1em;
}

.cyog {
  &-product {
    @include breakpoint($medium-up) {
      height: auto;
      float: none;
      display: table-cell;
      padding: 10px 10px 55px;
    }
    &__title {
      @include breakpoint($medium-up) {
        height: auto;
      }
    }
  }
  &-category {
    &__product-container {
      @include breakpoint($medium-up) {
        border-collapse: separate;
        border-spacing: 10px;
      }
    }
  }
}
