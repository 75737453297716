.checkout {
  #signin-block {
    #new-account {
      height: auto;
      @include breakpoint($medium-up) {
        height: 490px;
      }
      .social-login__divider {
        margin-bottom: 0;
      }
    }
    #return-user {
      height: auto;
      @include breakpoint($medium-up) {
        height: 570px;
      }
    }
  }
  .legal-copy {
    padding-bottom: 0;
    @include breakpoint($medium-up) {
      padding-bottom: 15px;
    }
  }
  .social-login {
    &__terms {
      margin-top: 0;
    }
    &__email-opt-in {
      padding-top: 0;
      padding-bottom: 15px;
      @include breakpoint($medium-up) {
        padding-top: 15px;
      }
    }
  }
  &#viewcart {
    .error_messages {
      &,
      a {
        color: $color-error-message;
      }
      .threshold-messages,
      [id^='offer_deferred'] {
        &,
        a {
          color: $color-alert-message;
        }
      }
      [id^='offer_criteria_met'] {
        &,
        a {
          color: $color-success-message;
        }
      }
    }
  }
}
